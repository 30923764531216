import { FileUploadReturn, useFileUpload } from "../../hooks/useFileUpload";
import { useCallback, useEffect, useState } from "react";

type RenderProps = (
  options: Omit<FileUploadReturn, "upload"> & {
    fileObject: any;
    reload: () => void;
  }
) => JSX.Element;
type Props = {
  file: File | null;
  onUploaded?: (fileObject: any) => void;
  children: RenderProps;
};
export const FileUploader: React.FC<Props> = ({
  file,
  children,
  onUploaded,
}) => {
  const [fileObject, setFileObject] = useState<any>(null);
  const { upload, isError, isLoading } = useFileUpload();

  const start = async () => {
    if (!file) {
      throw new Error("No file");
    }

    const fileObject = await upload(file);
    onUploaded?.(fileObject);
    setFileObject(fileObject);
  };

  useEffect(() => {
    if (file) {
      start();
    }
  }, [file]);

  return children({
    isLoading,
    isError,
    reload: start,
    fileObject,
  });
};
