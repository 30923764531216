import { Form } from "react-final-form";
import {
  Avatar,
  Button,
  FormLabel,
  Grid,
  Link as MUILink,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Checkboxes, TextField } from "mui-rff";
import { LoadingButton } from "@mui/lab";
import { useContext, useState } from "react";
import { CognitoAuthContext } from "../../context/AuthContext/CognitoAuthContext";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { AuthContextInterface } from "../../context/AuthContext/AuthContext";
import { FileUploader } from "../../components/FileUploader/FileUploader";
import {getHelpCenterRoute, getPrivacyPolicyRoute, getTermsOfServiceRoute} from "../../routes";
import { Link } from "react-router-dom";

export type FormValues = {
  avatarURL: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  password: string;
  passwordConfirmation: string;
  isAgreeToPrivacy: boolean;
  code: string;
};

type Props = {
  initialValues?: Partial<FormValues>;
  onComplete?: () => void;
  onError?: () => void;
};

enum FormStep {
  SignUp,
  Confirmation,
}

export const SignUpForm: React.FC<Props> = ({
  initialValues,
  onComplete,
  onError,
}) => {
  const theme = useTheme();
  const [file, setFile] = useState<File | null>(null);
  const [step, setStep] = useState(FormStep.SignUp);
  const { signUp, login, confirm, resendConfirmation } = useContext(
    CognitoAuthContext
  ) as AuthContextInterface;

  const onSubmit = (values: FormValues) => {
    if (step === FormStep.SignUp) {
      return handleSignUp(values);
    }

    if (step === FormStep.Confirmation) {
      return handleConfirmation(values);
    }
  };

  const handleSignUp = async (values: FormValues) => {
    try {
      await signUp(values.email, values.password, [
        { name: "custom:firstName", value: values.firstName },
        { name: "custom:lastName", value: values.lastName },
        { name: "custom:jobTitle", value: values.jobTitle },
        { name: "email", value: values.email },
        { name: "custom:avatarURL", value: values.avatarURL || "" },
      ]);
      setStep(FormStep.Confirmation);
    } catch (error) {
      if ((error as any).name === "UsernameExistsException") {
        setStep(FormStep.Confirmation);
        return await resendConfirmation(values.email);
      }
      onError?.();
    }
  };

  const handleConfirmation = async (values: FormValues) => {
    try {
      await confirm(values.email, values.code);
      await login(values.email, values.password);
      onComplete?.();
    } catch (error) {
      onError?.();
    }
  };

  return (
    <Form
      validate={(values) => {
        const errors: any = {};
        if (!values.firstName) {
          errors.firstName = "Field is required";
        }
        if (!values.lastName) {
          errors.lastName = "Field is required";
        }

        if (!values.email) {
          errors.email = "Field is required";
        }

        if (!values.jobTitle) {
          errors.jobTitle = "Field is required";
        }

        if (!values.password) {
          errors.password = "Field is required";
        } else if (values.password.length < 8) {
          errors.password = "Minimum 8 characters";
        }

        if (!values.passwordConfirmation) {
          errors.passwordConfirmation = "Field is required";
        } else if (values.passwordConfirmation !== values.password) {
          errors.passwordConfirmation = "Passwords dont match";
        }

        if (!values.isAgreeToPrivacy) {
          errors.isAgreeToPrivacy = "Field is required";
        }

        return errors;
      }}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting, values, form }) => (
        <Stack component="form" onSubmit={handleSubmit} spacing={2}>
          {step === FormStep.SignUp && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 2 }}
                  >
                    <Avatar
                      src={values.avatarURL}
                      sx={{
                        width: "100px",
                        height: "100px",
                        background: theme.palette.background.paper,
                        borderRadius: `${theme.shape.borderRadius}px`,
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          color: theme.palette.grey[500],
                          fontSize: "32px",
                        }}
                      />
                    </Avatar>
                    <FileUploader
                      file={file}
                      onUploaded={({ downloadURL }) => {
                        form.change("avatarURL", downloadURL);
                      }}
                    >
                      {({ isLoading }) => {
                        return (
                          <>
                            <LoadingButton
                              loading={isLoading}
                              htmlFor="file-upload"
                              component="label"
                              variant="outlined"
                              sx={{ minWidth: "140px" }}
                            >
                              {values.avatarURL ? "Edit photo" : "Add photo"}
                              <input
                                type="file"
                                id="file-upload"
                                hidden
                                onChange={(event: any) => {
                                  setFile(event.target.files[0]);
                                }}
                              />
                            </LoadingButton>
                            {values.avatarURL && !isLoading && (
                              <Button
                                onClick={() => form.change("avatarURL", "")}
                                color="error"
                                variant="outlined"
                              >
                                Remove
                              </Button>
                            )}
                          </>
                        );
                      }}
                    </FileUploader>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="firstName" placeholder="First name" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField name="lastName" placeholder="Last name" />
                </Grid>
              </Grid>
              <TextField name="jobTitle" placeholder="Speciality" />
              <TextField name="email" placeholder="Email" />
              <TextField
                type="password"
                name="password"
                placeholder="Password"
              />
              <TextField
                type="password"
                name="passwordConfirmation"
                placeholder="Repeat the password"
              />
              <Checkboxes
                name="isAgreeToPrivacy"
                data={{
                  label: (
                    <Typography>
                      I agree with <MUILink
                        underline="none"
                        component={Link}
                        to={`${getHelpCenterRoute.path}/${getTermsOfServiceRoute.path}`}
                    >
                      terms and conditions
                    </MUILink>, and {" "}
                      <MUILink
                        underline="none"
                        component={Link}
                        to={`${getHelpCenterRoute.path}/${getPrivacyPolicyRoute.path}`}
                      >
                        privacy policy
                      </MUILink>
                    </Typography>
                  ),
                  value: true,
                }}
              />
              <LoadingButton
                type="submit"
                loading={submitting}
                variant="contained"
                fullWidth
                size="large"
              >
                Create an account
              </LoadingButton>
            </>
          )}
          {step === FormStep.Confirmation && (
            <>
              <TextField name="code" placeholder="Confirmation code" />
              <LoadingButton
                type="submit"
                loading={submitting}
                variant="contained"
                fullWidth
                size="large"
              >
                Confirm account
              </LoadingButton>
            </>
          )}
        </Stack>
      )}
    </Form>
  );
};
