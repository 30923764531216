import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
  Link as MUILink,
  Divider,
} from "@mui/material";
import { Page } from "../Page/Page";
import SupportIcon from "@mui/icons-material/Support";
import { Link, NavLink } from "react-router-dom";
import {
  getCareersRoute,
  getFAQRoute,
  getHelpCenterRoute,
  getHowItWorksRoute,
  getPrivacyPolicyRoute,
  getSafetyRoute,
  getServiceOffersRoute,
  getTermsOfServiceRoute,
} from "../../routes";
import mastercardImg from "../../resources/payments/mastercard.svg";
import visaImg from "../../resources/payments/visa-transparent.svg";
import { NewsletterForm } from "../../forms/NewsletterForm/NewsletterForm";
import { useSnackbar } from "notistack";
import { AppLogo } from "../AppLogo/AppLogo";
import { categories } from "../../models/categories";

export const Footer = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box sx={{ borderTop: `1px solid ${theme.palette.divider}`, pt: 6, pb: 3 }}>
      <Page>
        <Paper sx={{ py: 2, px: 3, mb: 5 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <AppLogo color={theme.palette.text.primary} />
            <Box
              color="text.secondary"
              sx={{ fontSize: "14px", fontStyle: "normal", textAlign: "right" }}
            >
              <Typography fontSize="inherit">Kilcoo IT Sp.z.o.o</Typography>
              <Typography
                fontSize="inherit"
                component="address"
                fontStyle="inherit"
              >
                525056681, Hoza str. 86, apt.210, Warsaw, Poland, 00-682
                <br />
                info@beblass.com
              </Typography>
            </Box>
          </Stack>
        </Paper>
        <Box sx={{ px: 2 }}>
          <Grid container sx={{ mb: 5 }}>
            <Grid item xs={12} md={2}>
              <Typography fontWeight="800" sx={{ mb: 3 }}>
                Categories
              </Typography>
              <Stack spacing={0.75} color="text.secondary">
                <MUILink
                  component={Link}
                  to={getServiceOffersRoute.path}
                  underline="none"
                >
                  <Typography fontWeight="600" fontSize="14px">
                    All categories
                  </Typography>
                </MUILink>
                {categories.map((value) => (
                  <MUILink
                    component={Link}
                    key={value}
                    to={`${
                      getServiceOffersRoute.path
                    }?categories=${encodeURIComponent(value)}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography fontWeight="600" fontSize="14px">
                      {value}
                    </Typography>
                  </MUILink>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography fontWeight="800" sx={{ mb: 3 }}>
                Help center
              </Typography>
              <Stack spacing={0.75} color="text.secondary">
                <MUILink
                  component={Link}
                  to={`${getHelpCenterRoute.path}/${getFAQRoute.path}`}
                  underline="none"
                >
                  <Typography fontWeight="600" fontSize="14px">
                    FAQ
                  </Typography>
                </MUILink>
                <MUILink
                  component={Link}
                  to={`${getHelpCenterRoute.path}/${getPrivacyPolicyRoute.path}`}
                  underline="none"
                >
                  <Typography fontWeight="600" fontSize="14px">
                    Privacy Policy
                  </Typography>
                </MUILink>
                <MUILink
                  component={Link}
                  to={`${getHelpCenterRoute.path}/${getTermsOfServiceRoute.path}`}
                  underline="none"
                >
                  <Typography fontWeight="600" fontSize="14px">
                    Terms of Service
                  </Typography>
                </MUILink>
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography fontWeight="800" sx={{ mb: 3 }}>
                Other
              </Typography>
              <Stack spacing={0.75} color="text.secondary">
                <MUILink
                  component={Link}
                  to={getCareersRoute.path}
                  underline="none"
                >
                  <Typography fontWeight="600" fontSize="14px">
                    Careers
                  </Typography>
                </MUILink>
                <MUILink
                  component={Link}
                  to={getSafetyRoute.path}
                  underline="none"
                >
                  <Typography fontWeight="600" fontSize="14px">
                    Trust safety
                  </Typography>
                </MUILink>
                <MUILink
                  component={Link}
                  to={getHowItWorksRoute.path}
                  underline="none"
                >
                  <Typography fontWeight="600" fontSize="14px">
                    How it work
                  </Typography>
                </MUILink>
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack spacing={1}>
                <Typography fontWeight="700">Get news</Typography>
                <NewsletterForm
                  onComplete={() => {
                    enqueueSnackbar(
                      "You have successfully subscribed to news!",
                      {
                        variant: "success",
                      }
                    );
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack
            direction={{ md: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="text.secondary" fontSize="14px" fontWeight="500">
              Copyright © 2023 BeBlass Technologies
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                py: 1,
                px: 3,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: "50px",
              }}
            >
              <Box component="img" src={visaImg} sx={{ width: "35px" }} />
              <Box component="img" src={mastercardImg} sx={{ width: "35px" }} />
              <Divider
                orientation="vertical"
                sx={{ width: "1px", height: "15px" }}
              />
              <MUILink
                component={Link}
                to={getHelpCenterRoute.path}
                underline="none"
              >
                <Button
                  startIcon={<SupportIcon color="primary" />}
                  sx={{
                    color: "text.primary",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Feedback
                </Button>
              </MUILink>
            </Stack>
          </Stack>
        </Box>
      </Page>
    </Box>
  );
};
