import { createContext, useEffect, useState } from "react";
import { AuthContextInterface, UserInterface } from "./AuthContext";
import {
  ConfirmSignUpCommand,
  GetUserCommand,
  ResendConfirmationCodeCommand,
  InitiateAuthCommand,
  UpdateUserAttributesCommand,
  VerifyUserAttributeCommand,
  SignUpCommand,
  GetUserCommandOutput,
} from "@aws-sdk/client-cognito-identity-provider";
import { useLocalStorage } from "usehooks-ts";
import {
  createCognitoIdentityServiceProvider,
  getCognitoClientID,
  getCognitoUserAttributeValue,
} from "../../utils/aws/cognito";

const clientID = getCognitoClientID();
const cognitoIdentityServiceProvider = createCognitoIdentityServiceProvider();
export const CognitoAuthContext = createContext<AuthContextInterface | null>(
  null
);

type Props = {
  children: React.ReactElement;
  fallback?: any;
};

export const CognitoAuthProvider: React.FC<Props> = ({
  fallback,
  children,
}) => {
  const [token, setToken] = useLocalStorage<string | null>("auth-token", "");
  const [currentUser, setCurrentUser] = useState<UserInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      setCurrentUser(null);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      getUser(token)
        .then((user) => {
          setCurrentUser(user);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [token]);

  const getUser = async (token: string): Promise<UserInterface | null> => {
    const command = new GetUserCommand({
      AccessToken: token,
    });
    const { UserAttributes = [] } = await cognitoIdentityServiceProvider.send(
      command
    );
    return {
      id: getCognitoUserAttributeValue(UserAttributes, "sub"),
      email: getCognitoUserAttributeValue(UserAttributes, "email"),
      firstName: getCognitoUserAttributeValue(
        UserAttributes,
        "custom:firstName"
      ),
      lastName: getCognitoUserAttributeValue(UserAttributes, "custom:lastName"),
      avatarURL: getCognitoUserAttributeValue(
        UserAttributes,
        "custom:avatarURL"
      ),
      jobTitle: getCognitoUserAttributeValue(UserAttributes, "custom:jobTitle"),
    };
  };

  const refetchUser = async () => {
    setToken(token);
  };

  const login = async (email: string, password: string) => {
    const command = new InitiateAuthCommand({
      AuthFlow: "USER_PASSWORD_AUTH",
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
      ClientId: clientID,
    });
    const data = await cognitoIdentityServiceProvider.send(command);
    const token = data.AuthenticationResult?.AccessToken as string;
    setToken(token);
  };

  const signUp = async (
    email: string,
    password: string,
    attributes: { name: string; value: string }[] = []
  ) => {
    const command = new SignUpCommand({
      ClientId: clientID,
      Username: email,
      Password: password,
      UserAttributes: attributes.map(({ name, value }) => ({
        Name: name,
        Value: value,
      })),
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const resendConfirmation = async (email: string) => {
    const command = new ResendConfirmationCodeCommand({
      Username: email,
      ClientId: clientID,
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const changeAttributes = async (
    attributes: { name: string; value: string }[] = []
  ) => {
    if (!token) {
      throw new Error("User is not authorised");
    }
    const command = new UpdateUserAttributesCommand({
      AccessToken: token,
      UserAttributes: attributes.map(({ name, value }) => ({
        Name: name,
        Value: value,
      })),
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const confirm = async (email: string, code: string) => {
    const command = new ConfirmSignUpCommand({
      ClientId: clientID,
      Username: email,
      ConfirmationCode: code,
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const confirmAttributeChange = async (attribute: string, code: string) => {
    if (!token) {
      throw new Error("User is not authorised");
    }
    const command = new VerifyUserAttributeCommand({
      AccessToken: token,
      AttributeName: attribute,
      Code: code,
    });
    await cognitoIdentityServiceProvider.send(command);
  };

  const logout = async () => {
    setToken("");
  };

  if (isLoading) {
    return fallback || null;
  }

  return (
    <CognitoAuthContext.Provider
      value={{
        isAuthorized: !!currentUser,
        changeAttributes,
        confirmAttributeChange,
        login,
        logout,
        signUp,
        confirm,
        currentUser,
        resendConfirmation,
        refetchUser,
      }}
    >
      {children}
    </CognitoAuthContext.Provider>
  );
};
