import {
  Box,
  Button,
  ButtonBase,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  StackProps,
  Typography,
  useTheme,
  Link as MUILink,
  Divider,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { AppSearch } from "../AppSearch/AppSearch";
import { AppLogo } from "../AppLogo/AppLogo";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  getBecomeSellerRoute,
  getLoginRoute,
  getMainPageRoute,
  getProfileRoute,
  getSignUpRoute,
} from "../../routes";
import { useContext, useEffect } from "react";
import {
  CurrencyContext,
  CurrencyContextInterface,
} from "../../context/CurrencyContext/CurrencyContext";
import { DialogButton } from "../DialogButton/DialogButton";
import { SignUpForm } from "../../forms/SignUpForm/SignUpForm";
import { CognitoAuthContext } from "../../context/AuthContext/CognitoAuthContext";
import { AuthContextInterface } from "../../context/AuthContext/AuthContext";
import LoginIcon from "@mui/icons-material/Login";
import { Toggle } from "../Toggle/Toggle";
import { LoginForm } from "../../forms/LoginForm/LoginForm";
import { useSnackbar } from "notistack";
import { MenuButton } from "../MenuButton/MenuButton";
import { CURRENCY_TO_NAME_MAP } from "../../context/CurrencyContext/currencyNames";
import { CURRENCY_TO_SYMBOL_MAP } from "../../context/CurrencyContext/currencySymbols";

type Props = {
  sx?: StackProps["sx"];
};
export const Header: React.FC<Props> = ({ sx = {} }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthorized, logout } = useContext(
    CognitoAuthContext
  ) as AuthContextInterface;
  const { change, currency, currencies, currencySymbol } = useContext(
    CurrencyContext
  ) as CurrencyContextInterface;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={5}
      sx={{
        width: "100%",
        ...sx,
      }}
    >
      <Link to={getMainPageRoute.path}>
        <AppLogo sx={{ height: "45px" }} color={theme.palette.secondary.main} />
      </Link>
      <AppSearch
        sx={{
          maxHeight: "54px",
          width: "400px",
          [theme.breakpoints.down("sm")]: { display: "none" },
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{
          ml: "auto !important",
          height: "54px",
          px: 3,
          backgroundColor: theme.palette.background.paper,
          borderRadius: "50px",
          color: "text.primary",
        }}
      >
        <Typography
          fontWeight="600"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <LanguageIcon color="primary" sx={{ mr: 1, fontSize: "18px" }} />
          English
        </Typography>
        <MenuButton
          renderButton={(open) => (
            <ButtonBase disableRipple disableTouchRipple onClick={open}>
              <Typography fontWeight="600" color="text.primary">
                {currencySymbol} {currency}
              </Typography>
            </ButtonBase>
          )}
          renderMenuItems={(close) =>
            currencies.map((currency) => (
              <MenuItem
                key={currency}
                onClick={() => {
                  change(currency);
                  close();
                }}
                value={currency}
              >
                ({currency}) {CURRENCY_TO_NAME_MAP[currency]}
              </MenuItem>
            ))
          }
        />
        <MUILink
          component={Link}
          to={getBecomeSellerRoute.path}
          color="inherit"
          underline="none"
        >
          <Typography fontWeight="600">Become a Seller</Typography>
        </MUILink>
        {isAuthorized ? (
          <MenuButton
            renderButton={(open) => (
              <IconButton onClick={open}>
                <AccountCircleIcon />
              </IconButton>
            )}
            renderMenuItems={(close) => [
              <NavLink
                to={getProfileRoute.path}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem>Profile</MenuItem>
              </NavLink>,
              <MenuItem onClick={logout}>Log out</MenuItem>,
            ]}
          />
        ) : (
          <>
            <MUILink
              component={Link}
              to={getLoginRoute.path}
              underline="none"
              color="inherit"
            >
              <Typography fontWeight="600">Sign in</Typography>
            </MUILink>
            <Divider
              orientation="vertical"
              sx={{ width: "1px", height: "15px" }}
            />
            <DialogButton
              renderButton={(open) => (
                <ButtonBase disableRipple onClick={open}>
                  <Typography
                    fontWeight="600"
                    color="inherit"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <LoginIcon
                      color="primary"
                      sx={{ mr: 1, fontSize: "18px" }}
                    />
                    Join
                  </Typography>
                </ButtonBase>
              )}
              maxWidth="sm"
              fullWidth
              renderDialogContent={(close) => (
                <Toggle>
                  {([isSignIn, { toggle }]) =>
                    isSignIn ? (
                      <>
                        <DialogTitle>Sign into your BeBlass ID</DialogTitle>
                        <DialogContent>
                          <Typography
                            color="text.secondary"
                            sx={{ mb: 3, fontSize: "14px" }}
                          >
                            If you don't have a BeBlass ID, please,{" "}
                            <ButtonBase
                              disableRipple
                              onClick={toggle}
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: "inherit",
                                fontWeight: "inherit",
                                fontFamily: "inherit",
                                lineHeight: "inherit",
                              }}
                            >
                              create one
                            </ButtonBase>
                          </Typography>
                          <LoginForm
                            onError={() => {
                              enqueueSnackbar("Incorrect credentials", {
                                variant: "error",
                              });
                            }}
                            onComplete={() => {
                              close();
                              navigate(getProfileRoute.path);
                              enqueueSnackbar(
                                "Successfully logged in to your ID",
                                {
                                  variant: "success",
                                }
                              );
                            }}
                          />
                        </DialogContent>
                      </>
                    ) : (
                      <>
                        <DialogTitle>Create an account BeBlass ID</DialogTitle>
                        <DialogContent>
                          <Typography
                            color="text.secondary"
                            sx={{ mb: 3, fontSize: "14px" }}
                          >
                            If you have an account,{" "}
                            <ButtonBase
                              disableRipple
                              onClick={toggle}
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: "inherit",
                                fontWeight: "inherit",
                                fontFamily: "inherit",
                                lineHeight: "inherit",
                              }}
                            >
                              log in
                            </ButtonBase>
                          </Typography>
                          <SignUpForm
                            onError={() => {
                              enqueueSnackbar(
                                "Something went wrong. We will fix it soon",
                                {
                                  variant: "error",
                                }
                              );
                            }}
                            onComplete={() => {
                              close();
                              navigate(getProfileRoute.path);
                              enqueueSnackbar(
                                "Successfully registered on the platform",
                                {
                                  variant: "success",
                                }
                              );
                            }}
                          />
                        </DialogContent>
                      </>
                    )
                  }
                </Toggle>
              )}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
