import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContextInterface } from "../../context/AuthContext/AuthContext";
import {
  getLoginRoute,
  getMainPageRoute,
  getNotFoundRoute,
} from "../../routes";
import { CognitoAuthContext } from "../../context/AuthContext/CognitoAuthContext";
import { PageLoader } from "../../components/PageLoader/PageLoader";

type Props = {
  children: React.ReactElement;
};
export const AuthGuard: React.FC<Props> = ({ children }) => {
  const { isAuthorized } = useContext(
    CognitoAuthContext
  ) as AuthContextInterface;

  if (!isAuthorized) {
    return <Navigate to={getLoginRoute.path} />;
  }

  return children;
};
