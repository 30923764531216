import { Components, Theme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const components: Components<Omit<Theme, "components">> | undefined = {
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
  },

  MuiAccordion: {
    styleOverrides: {
      expanded: ({ theme }) => ({}),
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        "&.Mui-expanded": {
          backgroundColor: `${theme.palette.background.paper}`,
        },
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: ({ theme }) => ({
        padding: `0 ${theme.spacing(1)}`,
        margin: `${theme.spacing(3)} 0`,
        "&.Mui-expanded": {
          margin: `${theme.spacing(3)} 0`,
        },
      }),
      expandIconWrapper: ({ theme }) => ({
        background: theme.palette.background.paper,
        borderRadius: "50px",
        padding: theme.spacing(0.5),
        "&.Mui-expanded": {
          backgroundColor: `${theme.palette.primary.main}`,
          color: theme.palette.common.white,
        },
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        paddingTop: 0,
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: "700",
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },

  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: ({ theme }) => ({
        backgroundColor: theme.palette.text.primary,
      }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: "capitalize",
        fontWeight: "800",
        minHeight: "60px",
        "&.Mui-selected": {
          color: theme.palette.text.primary,
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.background.paper,
        borderRadius: "50px",
        fontWeight: "500",
        // paddingTop: "50px !important",
      }),
      input: ({ theme }) => ({
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),
      }),
      notchedOutline: ({ theme }) => ({
        borderColor: theme.palette.background.paper,
      }),
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionEnd: ({ theme }) => ({
        top: "0px",
        right: "-5px",
        marginLeft: "-25px",
      }),
      root: ({ theme }) => ({
        position: "relative",
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.palette.common.white,
        borderRadius: "50px",
        minWidth: "32px",
        minHeight: "32px",
        zIndex: 5,
      }),
    },
  },

  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        "&:last-child": {
          paddingBottom: "null",
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        verticalAlign: "top",

        ":not(:last-child)": {
          borderRight: `1px solid ${theme.palette.divider}`,
        },
      }),
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: "separate",
        borderSpacing: 0,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: "15px",
        ":nth-of-type(even)": {
          backgroundColor: theme.palette.background.paper,
        },
        "& .MuiTableCell-root:first-child": {
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
        },
        "& .MuiTableCell-root:last-child": {
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
        },
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "50px",
      }),
      outlined: ({ ownerState }) => ({
        borderWidth: "2px",
        "&:hover": {
          borderWidth: "2px",
        },
      }),
      sizeLarge: ({ theme }) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      }),
    },
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        display: "flex",
      },
      root: ({ theme }) => ({
        ":hover": {
          backgroundColor: theme.palette.background.paper,
          borderRadius: "50px",
        },
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(3),
        marginRight: 0,
        alignItems: "center",
      }),
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: "40px",
        minWidth: "40px",
        borderRadius: `${theme.shape.borderRadius}px`,
        fontSize: "14px",
      }),
      outlined: ({ theme }) => ({
        background: theme.palette.secondary.main,
      }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontWeight: 700,
      },
      h2: {
        fontWeight: 700,
      },
      h3: {
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
      },
      h5: {
        fontWeight: 700,
      },
      h6: {
        fontWeight: 700,
      },
      body1: {
        lineHeight: "24px",
      },
      root: {
        fontWeight: 400,
      },
    },
  },
};
