import { createContext, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export interface CartContextInterface {
  ids: string[];
  add: (id: string) => void;
  remove: (id: string) => void;
  clear: () => void;
}

export const CartContext = createContext<CartContextInterface | null>(null);

type Props = {
  children: React.ReactElement;
};

export const CartProvider: React.FC<Props> = ({ children }) => {
  const [ids, setIds] = useLocalStorage<string[]>("cart-ids", []);

  const add = (id: string) => {
    const isAddedBefore = ids.some((value) => value === id);
    if (!isAddedBefore) {
      setIds([...ids, id]);
    }
  };

  const remove = (id: string) => {
    const isAddedBefore = ids.includes(id);
    if (isAddedBefore) {
      const nextIds = ids.filter((value) => value !== id);
      setIds(nextIds);
    }
  };

  const clear = () => {
    setIds([]);
  };

  return (
    <CartContext.Provider
      value={{
        ids,
        remove,
        add,
        clear,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
