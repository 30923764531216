import { createRoute } from "../utils/createRoute";

export const getMainPageRoute = createRoute("/");
export const getBecomeSellerRoute = createRoute("/become-seller");
export const getCareersRoute = createRoute("/careers");
export const getSafetyRoute = createRoute("/safety");
export const getHowItWorksRoute = createRoute("/how-it-works");
export const getHelpCenterRoute = createRoute("/help-center");
export const getFAQRoute = createRoute("faq");
export const getPrivacyPolicyRoute = createRoute("privacy-policy");
export const getSupportRoute = createRoute("support");
export const getTermsOfServiceRoute = createRoute("terms");
export const getPaymentsAndRefundsRoute = createRoute("payments-and-refunds");
export const getIntellectualPropertyClaimsRoute = createRoute(
  "intellectual-property-claims"
);
export const getServiceOffersRoute = createRoute("/service-offers");
export const getServiceOfferRoute = createRoute(
  "/service-offers/:serviceOfferId"
);
export const getCreateServiceOfferRoute = createRoute("/service-offers/create");

export const getCartRoute = createRoute("/cart");
export const getProfileRoute = createRoute("/profile");

export const getLoginRoute = createRoute("/login");
export const getSignUpRoute = createRoute("/signup");
export const getNotFoundRoute = createRoute("/404");
