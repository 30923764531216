import { ApolloClient, createHttpLink } from "@apollo/client";
import { cache } from "./cache";
import { setContext } from "@apollo/client/link/context";
import {
  AUTH_TOKEN_STORAGE_KEY,
  CURRENCY_STORAGE_KEY,
} from "../utils/constants";

const { REACT_APP_GQL_URL } = process.env;
const httpLink = createHttpLink({
  uri: REACT_APP_GQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const currency = localStorage.getItem(CURRENCY_STORAGE_KEY);
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: token?.slice(1, -1) } : {}),
      // ...(currency
      //   ? {
      //       [`x-${CURRENCY_STORAGE_KEY}`.toLowerCase()]: currency?.slice(1, -1),
      //     }
      //   : {}),
    },
  };
});

export const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});
