import { createContext } from "react";
import { useDarkMode, useLocalStorage } from "usehooks-ts";

export type ThemeSettingsContextInterface = {
  isContainerFixed: boolean;
  isSubheaderShown: boolean;
  isSidebarShown: boolean;
  isSidebarEnabled: boolean;
  isLayoutPinned: boolean;
  isDarkMode: boolean;
  isRTLEnabled: boolean;
  toggleContainerFixed: () => void;
  toggleSubheaderShown: () => void;
  toggleSidebarShown: () => void;
  toggleSidebarEnabled: () => void;
  toggleLayoutPinned: () => void;
  toggleDarkModeEnabled: () => void;
  toggleRTLEnabled: () => void;
  toggleFullScreenEnabled: () => void;
};

export const ThemeSettingsContext =
  createContext<ThemeSettingsContextInterface | null>(null);

type Props = {
  children: React.ReactElement;
};

export const ThemeSettingsProvider: React.FC<Props> = ({ children }) => {
  const [isContainerFixed, setIsContainerFixed] = useLocalStorage(
    "theme-is-container-fixed",
    true
  );
  const [isSubheaderShown, setIsSubheaderShown] = useLocalStorage(
    "theme-is-subheader-show",
    true
  );
  const [isSidebarEnabled, setIsSidebarEnabled] = useLocalStorage(
    "theme-is-sidebar-enabled",
    true
  );
  const [isSidebarShown, setIsSidebarShown] = useLocalStorage(
    "theme-is-sidebar-shown",
    true
  );
  const [isLayoutPinned, setIsLayoutPinned] = useLocalStorage(
    "theme-is-layout-pinned",
    false
  );
  const [isRTLEnabled, setIsRTLEnabled] = useLocalStorage(
    "theme-is-rtl-enabled",
    false
  );
  const { isDarkMode, toggle: toggleDarkModeEnabled } = useDarkMode(false);

  const toggleContainerFixed = () => {
    setIsContainerFixed(!isContainerFixed);
  };

  const toggleSubheaderShown = () => {
    setIsSubheaderShown(!isSubheaderShown);
  };

  const toggleSidebarShown = () => {
    setIsSidebarShown(!isSidebarShown);
  };

  const toggleLayoutPinned = () => {
    setIsLayoutPinned(!isLayoutPinned);
  };

  const toggleSidebarEnabled = () => {
    setIsSidebarEnabled(!isSidebarEnabled);
  };

  const toggleRTLEnabled = () => {
    setIsRTLEnabled(!isRTLEnabled);
  };

  const toggleFullScreenEnabled = async () => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    } else {
      await document.documentElement.requestFullscreen();
    }
  };

  return (
    <ThemeSettingsContext.Provider
      value={{
        isContainerFixed,
        isDarkMode,
        isLayoutPinned,
        isRTLEnabled,
        isSidebarEnabled,
        isSidebarShown,
        isSubheaderShown,
        toggleContainerFixed,
        toggleDarkModeEnabled,
        toggleFullScreenEnabled,
        toggleLayoutPinned,
        toggleRTLEnabled,
        toggleSidebarEnabled,
        toggleSidebarShown,
        toggleSubheaderShown,
      }}
    >
      {children}
    </ThemeSettingsContext.Provider>
  );
};
