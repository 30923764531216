import { lighten, PaletteOptions } from "@mui/material";

export const lightPalette: PaletteOptions = {
  background: {
    default: "#ffffff",
    paper: "#F6F6F7",
  },
  divider: "#F0F0F0",
  error: {
    main: "#E95F59",
    light: "#FCE6E6",
    contrastText: "#E82E2E",
  },
  primary: {
    dark: "#237FFF",
    light: "#CCE1FF",
    main: "#237FFF",
  },
  warning: {
    dark: "#654201",
    main: "#FFB424",
    light: "#FFF6E6",
  },
  secondary: {
    dark: "#95CE1B",
    light: lighten("#95CE1B", 0.3),
    main: "#95CE1B",
  },
  success: {
    main: "#5DCB98",
  },
  text: {
    primary: "#404145",
    secondary: "#74767E",
  },
};
