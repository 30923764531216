import { createContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useApolloClient } from "@apollo/client";
import { CURRENCY_STORAGE_KEY } from "../../utils/constants";
import { gql } from "@apollo/client";
import { CURRENCY_TO_SYMBOL_MAP } from './currencySymbols';
import currencySymbols from './currencySymbols.json';
// import currencyRates from "./currencyRates.json"; // Import the JSON file containing fixed rates

type CurrencyRates = {
  [key: string]: number;
}

const currencyRates: CurrencyRates = require("./currencyRates.json");

export interface CurrencyContextInterface {
  currencies: string[];
  currency: string;
  change: (currency: string) => void;
  convert: (amount: number, fromCurrency: string, toCurrency: string) => number;
  currencySymbol: string; // Add the currencySymbol property
}

export const CurrencyContext = createContext<CurrencyContextInterface | null>(
  null
);

type Props = {
  children: React.ReactElement;
};

const DEFAULT_CURRENCY = "EUR";

export const CurrencyProvider: React.FC<Props> = ({ children }) => {
  const client = useApolloClient();
  const [currency, setCurrency] = useLocalStorage<string>(
    CURRENCY_STORAGE_KEY,
    DEFAULT_CURRENCY
  );

  const change = (value: string) => {
    setCurrency(value);
    const symbol = getCurrencySymbol(value);
    console.log(`CurrencyContext.tsx: currency changed to ${value} with symbol ${symbol}`);
  
    // Debugging logs
    console.log('Current currency:', value);
    console.log('Currency symbols:', currencySymbols);
    };

  const convert = (amount: number, fromCurrency: string, toCurrency: string) => {
    if (!currencyRates.hasOwnProperty(fromCurrency) || !currencyRates.hasOwnProperty(toCurrency)) {
      console.error(`Invalid currency: ${fromCurrency} or ${toCurrency}`);
      return amount; // Return the original amount if conversion rate is not found
    }
  
    const fromRate = currencyRates[fromCurrency];
    const toRate = currencyRates[toCurrency];
    if (!fromRate || !toRate) {
      console.error(`Conversion rate not found for ${fromCurrency} or ${toCurrency}`);
      return amount; // Return the original amount if conversion rate is not found
    }
  
    return (amount / fromRate) * toRate;
  };

  useEffect(() => {
    const hasItem = localStorage.getItem(CURRENCY_STORAGE_KEY);
    if (!hasItem) {
      setCurrency(DEFAULT_CURRENCY);
    }
  }, []);  

  function getCurrencySymbol(currency: string): string {
    return (currencySymbols as Record<string, string>)[currency] || '?';
  }

  const currencySymbol = getCurrencySymbol(currency); // Implement getCurrencySymbol function to retrieve the symbol based on the currency

  return (
    <CurrencyContext.Provider
      value={{
        currencies: Object.keys(currencyRates), // Get the list of available currencies from the JSON file
        currency,
        change,
        convert,
        currencySymbol, // Include currencySymbol in the context value
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};