import { createTheme, responsiveFontSizes } from "@mui/material";
import { shape } from "./shape";
import { typography } from "./typography";
import { components } from "./components";
import { breakpoints } from "./breakpoints";
import { lightPalette } from "./palette/lightPalette";
import { darkPalette } from "./palette/darkPalette";

const muiTheme = responsiveFontSizes(
  createTheme({
    breakpoints,
    components,
    shape,
    typography,
  })
);

export const getLightTheme = (direction: string) =>
  createTheme(muiTheme, {
    direction,
    palette: {
      mode: "light",
      ...lightPalette,
    },
  });

export const getDarkTheme = (direction: string) =>
  createTheme(muiTheme, {
    direction,
    palette: {
      mode: "dark",
      ...darkPalette,
    },
  });
