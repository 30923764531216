import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { lazily } from "react-lazily";
import { BaseLayout } from "../layout/BaseLayout/BaseLayout";
const { MainPage } = lazily(() => import("../pages/MainPage/MainPage"));
const { FAQPage } = lazily(() => import("../pages/FAQPage/FAQPage"));
const { NotFoundPage } = lazily(
  () => import("../pages/NotFoundPage/NotFoundPage")
);
const { HelpCenterPage } = lazily(
  () => import("../pages/HelpCenterPage/HelpCenterPage")
);
const { SupportPage } = lazily(
  () => import("../pages/SupportPage/SupportPage")
);
const { PrivacyPolicyPage } = lazily(
  () => import("../pages/PrivacyPolicyPage/PrivacyPolicyPage")
);
const { TermsOfServicePage } = lazily(
  () => import("../pages/TermsOfServicePage/TermsOfServicePage")
);
const { ProfilePage } = lazily(
  () => import("../pages/ProfilePage/ProfilePage")
);
const { LoginPage } = lazily(() => import("../pages/LoginPage/LoginPage"));
const { SignUpPage } = lazily(() => import("../pages/SignUpPage/SignUpPage"));
const { BecomeSellerPage } = lazily(
  () => import("../pages/BecomeSellerPage/BecomeSellerPage")
);
const { ServiceOffersPage } = lazily(
  () => import("../pages/ServiceOffersPage/ServiceOffersPage")
);
const { ServiceOfferPage } = lazily(
  () => import("../pages/ServiceOfferPage/ServiceOfferPage")
);
const { CareersPage } = lazily(
  () => import("../pages/CareersPage/CareersPage")
);
const { PaymentsAndRefunds } = lazily(
  () => import("../pages/PaymentsAndRefunds/PaymentsAndRefunds")
);
const { SafetyPage } = lazily(() => import("../pages/SafetyPage/SafetyPage"));
const { HowItWorksPage } = lazily(
  () => import("../pages/HowItWorksPage/HowItWorksPage")
);
const { CreateServiceOfferPage } = lazily(
  () => import("../pages/CreateServiceOfferPage/CreateServiceOfferPage")
);
// CLI-PAGE-IMPORT
import {
  getFAQRoute,
  getMainPageRoute,
  getNotFoundRoute,
  getTermsOfServiceRoute,
  getProfileRoute,
  getLoginRoute,
  getSignUpRoute,
  getPrivacyPolicyRoute,
  getHelpCenterRoute,
  getSupportRoute,
  getBecomeSellerRoute,
  getServiceOffersRoute,
  getServiceOfferRoute,
  getCareersRoute,
  getSafetyRoute,
  getHowItWorksRoute,
  getCreateServiceOfferRoute,
  getPaymentsAndRefundsRoute,
  // CLI-ROUTE-IMPORT
} from "./index";
import { AuthGuard } from "../guards/AuthGuard/AuthGuard";
import { GuestGuard } from "../guards/GuestGuard/GuestGuard";
import { useEffect } from "react";

export const Routes: React.FC = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if ((window as any).gtag) {
      (window as any).gtag("event", "page_view", {
        page_path: pathname + search,
      });
    }
  }, [pathname, search]);

  return useRoutes([
    {
      children: [
        { element: <ProfilePage />, path: getProfileRoute.path },
        {
          element: <CreateServiceOfferPage />,
          path: getCreateServiceOfferRoute.path,
        },
        // CLI-AUTH-PAGE-RENDER
      ],
      element: (
        <AuthGuard>
          <BaseLayout />
        </AuthGuard>
      ),
    },
    {
      children: [
        { element: <LoginPage />, path: getLoginRoute.path },
        { element: <SignUpPage />, path: getSignUpRoute.path },
        // CLI-GUEST-PAGE-RENDER
      ],
      element: (
        <GuestGuard redirectTo={getProfileRoute.path}>
          <BaseLayout />
        </GuestGuard>
      ),
    },
    {
      children: [
        { element: <MainPage />, path: getMainPageRoute.path },
        {
          element: <HelpCenterPage />,
          path: getHelpCenterRoute.path,
          children: [
            { element: <FAQPage />, path: getFAQRoute.path },
            {
              element: <PrivacyPolicyPage />,
              path: getPrivacyPolicyRoute.path,
            },
            {
              element: <TermsOfServicePage />,
              path: getTermsOfServiceRoute.path,
            },
            {
              element: <PaymentsAndRefunds />,
              path: getPaymentsAndRefundsRoute.path,
            },
            {
              element: <SupportPage />,
              path: getSupportRoute.path,
            },
            {
              element: <Navigate to={getFAQRoute.path} replace />,
              path: "",
            },
          ],
        },
        { element: <BecomeSellerPage />, path: getBecomeSellerRoute.path },
        { element: <ServiceOffersPage />, path: getServiceOffersRoute.path },
        { element: <ServiceOfferPage />, path: getServiceOfferRoute.path },
        { element: <CareersPage />, path: getCareersRoute.path },
        { element: <SafetyPage />, path: getSafetyRoute.path },
        { element: <HowItWorksPage />, path: getHowItWorksRoute.path },
        // CLI-PAGE-RENDER
        { element: <NotFoundPage />, path: getNotFoundRoute.path },
        {
          element: <Navigate to={getNotFoundRoute.path} replace />,
          path: "*",
        },
      ],
      element: <BaseLayout />,
      path: "/",
    },
  ]);
};
