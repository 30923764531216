import { categories } from "../../models/categories";
import { Link as MUILink, Stack, StackProps } from "@mui/material";
import { Link } from "react-router-dom";
import { getServiceOffersRoute } from "../../routes";

type Props = {
  sx?: StackProps["sx"];
};
export const SubHeader: React.FC<Props> = ({ sx = {} }) => {
  return (
    <Stack direction="row" spacing={4} sx={{ ...sx, width: "100%" }}>
      {categories.map((value) => (
        <MUILink
          component={Link}
          to={`${getServiceOffersRoute.path}?categories=${encodeURIComponent(
            value
          )}`}
          underline="none"
          color="text.primary"
        >
          {value}
        </MUILink>
      ))}
    </Stack>
  );
};
