import { Container, ContainerProps } from "@mui/material";
import { useThemeSettings } from "../../hooks/useThemeSettings";

export type Props = {
  children?: any;
  sx?: ContainerProps["sx"];
  maxWidth?: ContainerProps["maxWidth"];
};

export const Page: React.FC<Props> = ({
  children,
  sx = {},
  maxWidth: maxWidthProp,
}) => {
  const { isContainerFixed } = useThemeSettings();
  const maxWidth = maxWidthProp || (isContainerFixed ? "lg" : "xl");

  return (
    <Container
      component="section"
      sx={{ ...sx, width: "100%" }}
      maxWidth={maxWidth}
    >
      {children}
    </Container>
  );
};
