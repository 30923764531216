import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  StackProps,
  TextField,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { gql, useQuery } from "@apollo/client";
import {
  CurrencyContext,
  CurrencyContextInterface,
} from "../../context/CurrencyContext/CurrencyContext";
import { SERVICE_OFFER } from "../../queries/serviceOffer";

type Props = {
  sx?: StackProps["sx"];
};
export const AppSearch: React.FC<Props> = ({ sx = {} }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [term, setTerm] = useState("");
  const debouncedValue = useDebounce(term, 500);

  const query = useQuery(SERVICE_OFFER.SERVICE_OFFERS_SEARCH, {
    variables: {
      name: debouncedValue,
      take: 10,
      sortBy: "rating",
      sortDirection: "DESC",
    },
    skip: !debouncedValue,
  });

  const options =
    query.data?.serviceOffersPaged?.serviceOffers.map((serviceOffer: any) => ({
      id: serviceOffer.id,
      name: serviceOffer.title,
    })) || [];

  return (
    <Autocomplete
      sx={{ width: "auto" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option: any, value: any) =>
        option.name === value.name
      }
      onInputChange={(event, value, reason) => {
        setTerm(value);
      }}
      onChange={(event, value, reason, details) => {
        setTerm("");
      }}
      inputValue={term}
      getOptionLabel={(option: any) => option.name}
      options={options}
      loading={query.loading}
      fullWidth
      forcePopupIcon={false}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            ...sx,
            ".MuiInputBase-root": {
              p: "3px !important",
              pr: "4px !important",
              borderRadius: "50px",
              backgroundColor: theme.palette.background.paper,
              fontWeight: "600",
            },
            ".MuiInputBase-input": {
              px: `${theme.spacing(2)} !important`,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.background.paper,
            },
            ".MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.background.paper,
            },
          }}
          fullWidth
          size="small"
          placeholder={`Try "building mobile app"`}
          color="primary"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  mr: 0,
                  display: "flex",
                  justifyContent: "center",
                  minWidth: "47px",
                  minHeight: "47px",
                  background: theme.palette.primary.main,
                  borderRadius: "50px",
                  color: theme.palette.common.white,
                }}
              >
                {query.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
