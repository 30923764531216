import { gql } from "@apollo/client";

export const SERVICE_OFFER = {
  SERVICE_OFFERS_PAGED: gql`
    query ServiceOffersPaged(
      $sortBy: String
      $sortDirection: SortDirection
      $take: Float
    ) {
      serviceOffersPaged(
        sortBy: $sortBy
        sortDirection: $sortDirection
        take: $take
      ) {
        serviceOffers {
          id
          title
          description
          rating
          photoURL
          rating
          reviewsCount
          priceMin
          author {
            id
            name
            avatarURL
          }
        }
        count
      }
    }
  `,
  SERVICE_OFFERS_SEARCH: gql`
    query ServiceOffersSearch(
      $sortBy: String
      $sortDirection: SortDirection
      $take: Float
      $title: String
    ) {
      serviceOffersPaged(
        sortBy: $sortBy
        sortDirection: $sortDirection
        take: $take
        title: $title
      ) {
        serviceOffers {
          id
          title
        }
        count
      }
    }
  `,
};
