import { Box, Container, useTheme } from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { getMainPageRoute } from "../../routes";

export const BaseLayout: React.FC = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isMainPage = pathname === getMainPageRoute.path;

  return (
    <>
      <Box
        component="header"
        sx={{
          [theme.breakpoints.up("sm")]: { position: "sticky", top: 0 },
          [theme.breakpoints.down("sm")]: {
            position: "fixed",
            width: "100%",
          },
          background: theme.palette.common.white,
          ...(!isMainPage
            ? { borderBottom: `1px solid ${theme.palette.divider}`, mb: 3 }
            : {}),
          zIndex: 1100,
        }}
      >
        <Container maxWidth="lg">
          <Header
            sx={{
              py: 2,
              [theme.breakpoints.down("sm")]: {
                justifyContent: "space-between",
              },
            }}
          />
          {!isMainPage && <SubHeader sx={{ py: 2 }} />}
        </Container>
      </Box>
      <Box component="main">
        <Outlet />
      </Box>
      <Box component="footer" sx={{ width: "100%" }}>
        <Footer />
      </Box>
    </>
  );
};
